
/* eslint-disable */
import { defineComponent, ref } from 'vue'
import { ErrorMessage, Field, Form } from 'vee-validate'
import { Actions } from '@/store/enums/StoreEnums'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import Swal from 'sweetalert2/dist/sweetalert2.min.js'
import * as Yup from 'yup'
import ApiService from '@/core/services/ApiService'
import { useI18n } from 'vue-i18n'

interface schoolDB {
  name?: string
  adress: string
  createdAt: Date
  dbName: string
  headMaster: string
  legalName: string
  logo: string
  phone: number
  type: string
  updatedAt: Date
  _id: string
  base_url?: string
}

export default defineComponent({
  name: 'sign-in',
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore()
    const router = useRouter()

    const options = ref<schoolDB[]>([])
    const selectedDB = ref<schoolDB | undefined>(undefined)
    const selectedDBName = ref<string>('default')
    const submitButton = ref<HTMLElement | null>(null)
    const { t } = useI18n()

    ApiService.setHeaderBuilding('Root')
    ApiService.post(process.env.VUE_APP_API_URL + '/api/web/buildings/filter', {
      query: { active: true, 'access.edLearning.active': true },
    })
      .then(({ data }) => {
        data.forEach((option: schoolDB) => {
          let urlSuffix = option.base_url
            ? option.base_url + '/'
            : process.env.VUE_APP_API_URL + '/'
          console.log(urlSuffix)
          if (option.logo)
            option.logo = urlSuffix + option.logo.replace('\\', '/')
          else option.logo = 'media/logos/edtrust.png'
        })
        console.log(data)
        options.value = data
      })
      .catch((e) => console.log(e))

    //Create form validation object
    const login = Yup.object().shape({
      email: Yup.string().required(t('editProfile.requiredEmail')),
      password: Yup.string()
        .min(4, t('editProfile.passwordRule'))
        .required(t('editProfile.passwordRule')),
      schoolName: Yup.object()
        .required(t('editProfile.requiredSchool'))
        .nullable(),
    })

    //Form submit function
    const onSubmitLogin = (values) => {
      // Clear existing errors
      store.dispatch(Actions.LOGOUT)

      if (submitButton.value) {
        // Activate indicator
        submitButton.value.setAttribute('data-kt-indicator', 'on')
      }
      // Dummy delay
      setTimeout(() => {
        // Send login request
        console.log(selectedDB.value)
        ApiService.setHeaderBuilding(selectedDB.value!.dbName)
        if (selectedDB.value?.base_url) {
          ApiService.setBaseUrl(selectedDB.value?.base_url)
          let config = {
            base_url: selectedDB.value?.base_url,
            dbName: selectedDB.value?.dbName,
            building: selectedDB.value,
          }
          console.log('config', config)
          store.commit('setServerConfig', config)
        } else ApiService.setBaseUrl(process.env.VUE_APP_API_URL)
        // Send login request
        store
          .dispatch(Actions.LOGIN, values)
          .then(async (data) => {
            console.log('data', data)

            Swal.fire({
              text: 'Bienvenue!',
              icon: 'success',
              showConfirmButton: false,
              timer: 1000,
            }).then(function () {})
            // Go to page after successfully login
            setTimeout(() => {
              router.push({ name: 'dashboard' })
            }, 1000)
            //Deactivate indicator
            submitButton.value?.removeAttribute('data-kt-indicator')
          })
          .catch((e) => {
            console.log(e)
            Swal.fire({
              text: "Nom d'utlisateur ou mot de passe incorrect !",
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: 'Essayer de nouveau!',
              customClass: {
                confirmButton: 'btn fw-bold btn-light-danger',
              },
            })
            //Deactivate indicator
            submitButton.value?.removeAttribute('data-kt-indicator')
          })
        //Deactivate indicator
        submitButton.value?.removeAttribute('data-kt-indicator')
      }, 1000)
    }

    const selectDB = (db: schoolDB) => {
      selectedDB.value = db
      selectedDBName.value = db.dbName
    }

    return {
      selectDB,
      selectedDB,
      selectedDBName,
      options,
      onSubmitLogin,
      login,
      submitButton,
      termsLink: process.env.VUE_APP_TERMS_LINK,
      acceptedTerms: ref(false),
    }
  },
})
